import { render, staticRenderFns } from "./LoanInterface.vue?vue&type=template&id=467c3332&scoped=true&"
import script from "./LoanInterface.vue?vue&type=script&lang=js&"
export * from "./LoanInterface.vue?vue&type=script&lang=js&"
import style0 from "./LoanInterface.vue?vue&type=style&index=0&id=467c3332&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "467c3332",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VPagination})
